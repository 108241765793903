import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';

const initCars = function() {
    document.querySelectorAll('.front-car-slider').forEach( el => {
        new Splide(el,{
            fixedWidth: true,
            gap: 30,
            arrows: false,
            pagination: false,
            wheel: true,
            releaseWheel: true,
            padding: { left: 60, right: 60 },
            breakpoints: {
                1440: {
                    gap: 20,
                    padding: { left: 40, right: 40 }
                },
                980: {
                    padding: { left: 20, right: 20 }
                },
                500: {
                    padding: { left: 10, right: 10 }
                }
            }
        }).mount();
    })
}

const initMainSlider = function () {
    const el = document.querySelector('#welcome-slider2')
    if (el) {
        new Splide(el,{
            type   : 'loop',
            fixedWidth: true,
            arrows: false,
        }).mount();
    } else {
        console.log('net')
    }
}

const initPresentationModelSliders = function () {
    document.querySelectorAll('.brand-model-slider').forEach( el => {
        new Splide(el,{
            fixedWidth: true,
            gap: 30,
            arrows: false,
            pagination: false,
            wheel: true,
            releaseWheel: true,
            padding: { left: 60, right: 60 },
            breakpoints: {
                1440: {
                    gap: 20,
                    padding: { left: 40, right: 40 }
                },
                980: {
                    padding: { left: 20, right: 20 }
                },
                500: {
                    padding: { left: 10, right: 10 }
                }
            }
        }).mount();
    })
}

const initSafetySlider = function() {
    document.querySelectorAll('.safety-howto-list').forEach( el => {
        console.log(el)
        new Splide(el,{
            fixedWidth: true,
            gap: 40,
            arrows: false,
            pagination: false,
            wheel: true,
            releaseWheel: true,
            padding: { left: 60, right: 60 },
            breakpoints: {
                1280: {
                    padding: { left: 40, right: 40 }
                },
                980: {
                    padding: { left: 20, right: 20 }
                },
                500: {
                    padding: { left: 10, right: 10 }
                }
            }
        }).mount();
    })
}


const SplideSliders = {
    initCars: initCars,
    initMainSlider: initMainSlider,
    initPresentationModelSliders: initPresentationModelSliders,
    initSafetySlider: initSafetySlider
}

export { SplideSliders as default };